@use "../../../master.scss";

.common-add-card-parent {
  border: 1px solid master.$border-color;
  border-radius: 12px;
  min-width: 235px;
  width: 100%;
  cursor: pointer;

  .cover-img {
    border-radius: 12px;
    width: 100%;
    height: 169px;
  }
  .card-content {
    padding: 20px;

    .total-cost {
      margin: 10px 0;
    }
    .counter {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      border: 1px solid master.$border-color;
      padding: 8px 10px;
      border-radius: 7px;
      height: 47px;

      .ant-input {
        width: 100px;
        border: none;
      }

      .count-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: master.$theme-color;
        border-radius: 50%;
        height: 27px;
        width: 27px;
      }
    }
  }
}
