@use "../../../master.scss";

.customer-parent {
  border: 1px solid master.$border-color;
  border-radius: 12px;
  padding: 20px;
  .search-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    border-bottom: 1px solid master.$border-color;
    padding-bottom: 20px;

    .search-parent {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }
  }
}

.action-parent {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    cursor: pointer;
  }
}
