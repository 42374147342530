@use "../../../master.scss";

.ant-picker {
  width: 100%;
  height: 56px;
  border-radius: 12px;
  // border: none;
  .ant-picker-input {
    padding-left: 8px;
  }
  input {
    font-size: 15px;

    &::placeholder {
      font-size: 14px !important;
      color: #96a0ad;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ant-picker {
    font-size: 13px !important;
    height: 38px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ant-picker {
    font-size: 14px !important;
    height: 40px !important;
  }
}
