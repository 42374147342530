@use "../../../master.scss";

.verification-parent {
    .otp-parent {
        gap: 20px;
        flex-wrap: wrap;
        input {
            width: 50px !important;
            height: 50px;
            border-radius: 10px !important;

            @include master.md {
                width: 20px;
                height: 40px;
            }
        }
    }

    .ant-progress-circle-path {
        stroke: master.$theme-color !important;
    }

    .resend-but {
        margin-top: 20px;
        text-align: center;
        p {
            cursor: pointer;
            color: master.$theme-color;
        }
    }

    .disable {
        p {
            cursor: not-allowed;
            opacity: 0.5 !important;
        }
    }
}
