.show-more-services {
  border-radius: 9px;
  border: 1px solid #ec373e;
  background: #ec373e;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    background-color: #fff !important;
    color: #ec373e !important;
  }
}

.calendarHeaderWrap {
  margin-bottom: 1rem;
}

.serviceItemWrap {
  border-radius: 12px;
  padding: 5px 10px;
  cursor: pointer;
}

.fc-theme-standard {
  border: 1px solid #dedede;
  border-radius: 12px;
  overflow: hidden;
}

.fc-liquid-hack {
  td {
    position: initial;
  }
}

.outer-calendar-wrap {
  background-color: #fff;
  border-radius: 12px;

  .fc-popover {
    z-index: 9 !important;
  }

  .fc-daygrid-day-frame {
    height: 200px;
  }
  .fc-event-selected {
    box-shadow: none;
    background-color: transparent !important;
  }
  .fc-event {
    background-color: transparent !important;
    border: none;
    &:focus {
      box-shadow: none;
      background-color: transparent !important;
    }
  }
  .fc-scrollgrid-liquid {
    border-radius: 12px;
    border-color: #dedede;
  }
  .fc-daygrid-day-events {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: end;
    gap: 8px;

    @media (max-width: 1200px) {
      // padding-left: 6px;
    }
  }
  .fc-daygrid-day-top {
    justify-content: start;

    .fc-daygrid-day-number {
      color: #2c3941;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0;
    }
  }
  .fc-daygrid-day.fc-day-today {
    background: transparent;
  }
  .fc-scrollgrid-sync-inner {
    padding: 10px;

    @media (max-width: 1200px) {
      padding: 6px;
    }

    a {
      color: #2c3941;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .fc-day-other {
    background: #fbfbfb;
    .fc-daygrid-day-top {
      opacity: 1;
    }
  }
}
