@use "../../master.scss";

.modal-component {
    .ant-modal-content {
        .ant-modal-close {
            top: 22px;
        }

        .modal-title {
            color: master.$theme-color;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.5px;
            margin-bottom: 15px;
        }

        @include master.md {
            padding: 20px 15px;
        }
    }
}
