@use "../../../master.scss";

h1.ant-typography {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    color: master.$text-color;
}

h3.ant-typography {
    font-size: 24px;
    line-height: 28.8px;
    // color: #ffffff;
}

h2.ant-typography {
    font-weight: 600;
    font-size: 18.72px;
    line-height: 28.8px;
    // color: #ffffff;
}

@media only screen and (max-width: 767px) {
    h1.ant-typography {
        font-size: 21px;
        line-height: 33px;
    }

    h2.ant-typography {
        font-size: 15.72;
        line-height: 25.8px;
        // color: #ffffff;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    h1.ant-typography {
        font-size: 22px;
        line-height: 34px;
    }
    h2.ant-typography {
        font-size: 18.72px;
        line-height: 28.8px;
    }
}
