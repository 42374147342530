@use "../../../../master.scss";

.slider-mobile {
    .slider {
        position: static;
        width: 100%;
        padding: 30px 10px;
    }
}

.slider {
    width: 40%;
    position: absolute;
    bottom: 0;
    right: 0;

    .but-parent {
        display: flex;
        align-items: center;
        justify-content: center;

        .arrow-but {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 50%;
            padding: 8px 0;
            background-color: #292e3d;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            p {
                color: #fff;
            }

            &:hover {
                background-color: #4c4e58;
            }

            &:disabled {
                opacity: 0.5;
                cursor: default;
            }
        }
    }
}

.slider-item {
    padding: 20px 10px;
    background-color: master.$theme-color;
    width: 100%;
    text-align: center;
    height: 150px;
    p {
        color: #fff;
    }
}

h1 {
    margin: 0;
    font-size: 2em;
}
