@use "../../../../master.scss";

.add-new-card {
    border: 1px solid master.$border-color;
    @include master.flex-between;
    padding: 15px 20px 15px 40px;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        border-color: master.$theme-color;
    }
}

.rfp {
    background-color: master.$theme-color;
    p {
        color: master.$white-color !important;
    }
}
