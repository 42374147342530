.login-parent {
    .remember-parent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -10px;
        .ant-form-item {
            margin: 0 !important;
        }
    }

    .ant-checkbox-inner {
        border: 4px solid;
    }
    .ant-checkbox-inner {
        width: 18px;
        height: 18px;
    }


          
    .ant-wave-target {
        &:hover{        
            background-color: #000 !important;
        }
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: #000;
        }
    }

    .button-parent {
        margin-top: 30px;
    }
}
