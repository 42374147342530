@use "../../../master.scss";

.dashboard {
  // height: 300px;
  // overflow: auto;

  .search-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    border-bottom: 1px solid master.$border-color;
    padding-bottom: 20px;

    .search-parent {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }
  }
}
