@use "../../../master.scss";

.my-uploader {
    @include master.flex-center;
    .ant-upload {
        position: relative;
        width: 150px;
        height: 150px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: master.$theme-light-color;
        border-radius: 50%;
        border: 1px solid master.$theme-color;
        .preview-img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
        }
        .upload-content {
            text-align: center;
            .camera {
                width: 44px;
                height: 33px;
            }
        }
        .edit-icon {
            position: absolute;
            right: 0;
            bottom: 10px;
            img {
                border: 2px solid #fff;
                border-radius: 50%;
                background: #fff;
            }
        }
    }
}

.secondary {
    @include master.flex-center;
    .ant-upload-select {
        position: relative;
        width: 100%;
        height: 150px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 2px dashed master.$theme-color;

        .ant-upload {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .preview-img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 6px;
            }
        }
    }
}
