@use "../../../master.scss";

.ant-pagination {
  text-align: center;
  margin-top: 40px;
  .anticon {
    font-size: 20px;
    color: master.$theme-color;
  }

  .ant-pagination-item {
    border-radius: 50%;
  }
  .ant-pagination-item-active {
    background-color: master.$theme-color;
    border-radius: 50%;
    color: #fff;
    a {
      color: #fff;
    }
  }
}
