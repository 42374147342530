@use "../../../../master.scss";

.common-price-book {
  h1 {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
    width: 140px;
  }
  .price-search {
    flex-wrap: wrap;
    gap: 10px;
  }
}

.ant-breadcrumb {
  position: absolute;
  top: -30px;

  @include master.md {
    position: static;
    margin-bottom: 10px;
  }
}
