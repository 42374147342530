/* Default button styles */

@use "../../../master.scss";

.small-but {
  .ant-btn {
    height: 40px;
    border-radius: 6px;
  }
}

button.ant-btn {
  font-size: 15px;
  height: 56px;
  width: 100%;
  min-width: 100px;
  border-radius: 12px;
  font-weight: 500;
  background-color: master.$theme-light-color;
  color: master.$theme-color;
  border: 1px solid master.$theme-color;

  .ant-btn-icon {
    margin-inline-end: 0px !important;
  }

  &:hover {
    opacity: 0.8 !important;
    background-color: master.$theme-light-color !important;
    color: master.$theme-color !important;
  }
}

.icon-but {
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media query for small screens */
@media only screen and (max-width: 767px) {
  button.ant-btn {
    font-size: 13px;
    height: 42px;
  }

  .small-but {
    .ant-btn {
      height: 35px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  button.ant-btn {
    font-size: 14px;
    height: 48px;
  }

  .small-but {
    .ant-btn {
      height: 35px;
    }
  }
}

:where(.css-dev-only-do-not-override-pfkssg).ant-btn-primary:disabled {
  color: master.$theme-color;
  background-color: master.$theme-color;
  opacity: 0.5;
}
