@use "../../../../master.scss";

.permission-parent {
    margin: 30px 0;
    .permission-items {
        display: flex;
        align-items: center;
        gap: 10px;

        .ant-form-item {
            margin-bottom: 0 !important;
        }
    }
}
