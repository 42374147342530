@use "../../../master.scss";

.common-text-area {
  border-radius: 12px;
  font-size: 15px !important;
  height: 170px !important;
  padding: 18px 22px;
  &::placeholder {
    color: #96a0ad;
    font-size: 14px;
  }
}
.ant-form-item-control-input-content {
  & ::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .common-text-area {
    height: 39px;
    font-size: 13px !important;

    // &::placeholder {
    //   font-size: 13.5px !important;
    // }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .common-text-area {
    font-size: 14px;
    height: 44px;

    // &::placeholder {
    //   font-size: 15px !important;
    // }
  }
}
