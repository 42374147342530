@use "../../../../master.scss";

.line-section {
  .address-text {
    margin: 10px 0;
  }

  .count {
    display: flex;
    height: 100%;
    align-items: center;
    p {
      margin-bottom: 15px !important;
    }
  }

  .role-divider {
    margin: -10px 0 15px 0;
  }

  .address-container {
    display: flex;
    gap: 20px;
    .delete-option {
      cursor: pointer;
      border-left: 1px solid #f43c3c;
      width: 100px;
      min-width: 25px;
      background-color: #f43c3c1a;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 9px 9px 0;
      margin-bottom: 15px;
    }
  }

  .address-divider {
    margin: 0px 0 15px 0;
  }
}

.total-parent {
  border: 1px solid master.$border-color;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 15px 0;
  .total {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    align-items: center;
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }

  .tax-select {
    height: 40px;
    .ant-select-selector {
      border: none;
      box-shadow: none !important;
      .ant-select-selection-item {
        padding-inline-end: 28px;
      }
    }
  }
}

.message {
}

.estimate-btn {
  margin-top: 20px;
  text-align: right;
  button {
    width: 200px;
  }
}
