@use "../../../master.scss";

.common-card-parent {
  border: 1px solid master.$border-color;
  border-radius: 12px;
  min-width: 235px;
  width: 100%;

  .cover-img {
    border-radius: 12px;
    width: 100%;
    height: 169px;
    cursor: pointer;
  }
  .card-content {
    padding: 20px;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
