.create-customer-parent {
  .address-text {
    margin: 10px 0;
  }

  .role-divider {
    margin: -10px 0 15px 0;
  }

  .address-container {
    display: flex;
    gap: 20px;
    .delete-option {
      cursor: pointer;
      border-left: 1px solid #f43c3c;
      width: 100px;
      background-color: #f43c3c1a;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 9px 9px 0;
      margin-bottom: 15px;
    }
  }

  .address-divider {
    margin: 0px 0 15px 0;
  }

  .small-but {
    text-align: end;
  }
}
