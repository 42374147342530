@use "../../../master.scss";

.select-user-parent {
    text-align: center;
    padding: 20px;

    .logo {
        width: 200px;
        height: 50px;
    }

    .select-user-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 30px;
        flex-wrap: wrap;
        height: calc(100vh - 100px);
        @include master.sm {
            height: 100%;
            margin: 20px 0;
        }

        .user-card {
            cursor: pointer;
            background-color: #18191a;
            border-radius: 20px;
            height: 400px;
            max-width: 280px;
            min-width: 200px;
            width: 100%;

            .card-text {
                padding: 20px 0 20px 20px !important;
            }
            .img-parent {
                display: flex;
                justify-content: center;
                height: calc(100% - 75px);
            }

            &:hover {
                box-shadow: 0px 5px 15px #0087a2;
            }
        }
    }
}
