@use "../../../../master.scss";

.estimate {
  .extra-field {
    border: 1px solid master.$border-color;
    padding: 15px 20px;
    border-radius: 7px;
    margin-top: 10px;
    overflow: hidden;
    transition: height 0.4s;

    .show {
      display: none;
    }

    &.expend {
      .show {
        margin-top: 10px;
        display: block;
      }
    }

    .source-tag-parent {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      .ant-tag {
        position: relative;
        color: #ffffff5a;
        background: black;
        border: none;
        padding: 4px 22px 4px 10px;
        border-radius: 176px;
        img {
          cursor: pointer;
          position: absolute;
          right: 8px;
          top: 10px;
        }
      }
    }
  }

  .img-preview {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .preview {
      position: relative;
      .img {
        border-radius: 10px;
      }
      .delete {
        position: absolute;
        top: -6px;
        right: -5px;
        cursor: pointer;
        img {
          background-color: white;
          border-radius: 50%;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .ant-upload-wrapper {
    width: 100%;
    display: grid;
  }
}
