@use "../../../master.scss";

.large {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    .back-img {
        width: 10px;
        height: 15px;
    }
}

.small {
    width: 21px;
    height: 21px;
    border-radius: 6px;
    .back-img {
        width: 5px;
        height: 9px;
    }
}

.arrow-but-parent {
    background-color: master.$theme-light-color;
    font-weight: 400;
    border: 2px solid master.$theme-color;
    cursor: pointer;
    @include master.flex-center;
}
