@use "../../../master.scss";

.home-wrapper {
  max-width: 2000px;
  margin: 0 auto;

  .drawerIcon {
    display: none;
    padding: 20px 40px;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    img {
      cursor: pointer;
    }

    @include master.md {
      display: flex;
    }
  }

  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    .header-content {
      display: flex;
      // gap: 20px;
      justify-content: space-around;
      width: 45%;

      @include master.lg {
        p {
          font-size: 16px !important;
        }
      }
    }
    .buttton-sec {
      flex-wrap: wrap;
      display: flex;
      gap: 10px;
      justify-content: end;

      @media (max-width: 930px) {
        width: 150px;
      }
    }
    @include master.md {
      display: none;
    }
  }

  .hero-section {
    position: relative;
    background-image: url("../../../assets/landingPage/Hero.png");
    height: 646px;
    background-size: cover;
    background-repeat: no-repeat;
    .hero-content {
      position: absolute;
      display: flex;
      align-items: center;
      height: 100%;
      padding-left: 60px;
    }
    .hero-heading {
      width: 600px;
      font-size: 72px;
      line-height: 80px;
    }

    @media (max-width: 1000px) {
      height: 346px;

      .hero-heading {
        width: 300px;
        font-size: 35px;
        line-height: 45px;
      }
    }

    @media (max-width: 500px) {
      height: 346px;

      .hero-content {
        padding-left: 20px;
      }

      .hero-heading {
        font-size: 30px;
        line-height: 45px;
        font-weight: 700;
      }
    }

    .slider {
      @include master.md {
        display: none;
      }
    }
  }

  .slider-mobile {
    @media (min-width: master.$screen-md) {
      display: none;
    }
  }

  .reputation-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 100px 200px;

    .reputaion-card {
      border: 1px solid #e0e3eb;
      border-radius: 4px;
      padding: 30px 15px;
    }

    @include master.md {
      margin: 20px;
    }
  }

  .about-section {
    position: relative;
    margin: 150px 80px;

    .img-container {
      width: 70%;
      min-height: 400px;
      img {
        width: 100%;
        height: 100%;
      }
      @include master.md {
        width: 100%;
      }
    }
    .about-container {
      position: absolute;
      top: 0;
      right: 60px;
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;

      .about {
        padding: 60px 40px 150px 40px;
        background-color: master.$black-color;
        p {
          color: #fff;
          font-size: 20px;
          line-height: 25px;
        }
        @include master.md {
          width: 100%;
        }
        @include master.sm {
          padding: 30px;
        }
        @include master.lg {
          padding: 20px;
        }
      }
      @include master.md {
        position: static;
        width: 100%;
        padding: 0 50px;
        margin-top: -150px;
      }

      @include master.sm {
        width: 100%;
        margin-top: -70px;
        padding: 0;
      }
    }
    @include master.md {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0;
    }

    @include master.lg {
      margin: 150px 20px;
    }
  }

  .services-section {
    background-color: #f6f8f7;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 90px 200px;
    .active {
      background-color: #0087a2;
      p {
        color: #fff !important;
      }
    }
    .unActive {
      background-color: #ffffff;
      box-shadow: 0px 0px 37px -2px #1118270d;
    }
    .reputaion-card {
      text-align: center;
      border-radius: 4px;
      padding: 40px 20px;
    }

    @include master.md {
      padding: 20px;
    }
  }

  .experience-section-parent {
    margin: 90px 120px;
    .exprience-section {
      display: flex;
      align-items: center;
      margin-left: 0 !important;
      margin-right: 0 !important;

      .card-section {
      }
      .content-section {
        .experience-heading {
          font-weight: 600;
          line-height: 70px;
          font-size: 60px;
          color: master.$theme-color;
          margin-bottom: 40px !important;

          @media (max-width: 1000px) {
            width: 300px;
            font-size: 35px;
            line-height: 45px;
          }
          @media (max-width: 500px) {
            font-size: 30px;
            line-height: 45px;
            font-weight: 700;
          }
        }

        .text {
          color: #525b7a;
        }
        .but-sub {
          font-weight: 600;
          margin-top: 30px;
          padding: 8px 30px;
          background-color: master.$theme-color;
          color: #fff;
          font-size: 18px;
          border: none;
          border-radius: 4px;
          a {
            color: #fff;
          }
        }
      }
    }
    @include master.md {
      margin: 50px 20px;
    }
  }

  .consultation-section {
    background-image: url("../../../assets/landingPage/consultantImg.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .consultation-container {
      padding: 100px 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;

      .get-container {
        border: 2px solid #fff;
        padding: 20px;
        cursor: pointer;
      }
      .consaltant-heading {
        p {
          font-size: 36px;
          line-height: 40px;
        }
      }
      p {
        color: #fff;
        a {
          color: #fff !important;
        }
      }

      @include master.md {
        padding: 50px 30px;
        .consaltant-heading {
          p {
            font-size: 30px !important;
            line-height: 35px;
          }
        }
      }
    }
  }

  .form-section {
    padding: 80px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f6f8f7;
    .form-text {
      max-width: 600px;
    }
    .form-container {
      max-width: 600px;

      // padding: 20px;

      input {
        height: 50px;
        padding: 0 0 0 10px;
        width: 100%;
        border: 1px solid #e0e3eb;
        font-size: 18px;

        &::placeholder {
          font-size: 18px;
          color: #a3aac2;
        }
      }

      textarea {
        padding: 10px 0 0 10px;
        width: 100%;
        border-color: #e0e3eb;
        font-size: 18px;

        &::placeholder {
          font-size: 18px;
          color: #a3aac2;
        }
      }
      p {
        margin-top: -20px !important;
        color: #a3aac2;
      }
    }
    .but-sub {
      font-weight: 600;
      margin-top: 30px;
      padding: 8px 30px;
      background-color: master.$theme-color;
      color: #fff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
    }
  }

  .footer-section {
    padding: 80px 70px;
    .footer-grid {
      justify-content: space-between;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .detail-parent {
      display: flex;
      gap: 20px;
      margin-bottom: 30px;
    }
    input {
      height: 40px;
      padding: 0 0 0 10px;
      border: 1px solid #e0e3eb;

      &::placeholder {
        color: #a3aac2;
      }
    }
    .but-sub {
      padding: 8px 30px;
      background-color: master.$theme-color;
      color: #fff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
    }

    @include master.md {
      padding: 100px 50px;
    }

    @include master.xs {
      padding: 50px 20px;
    }
  }

  .reserved-section {
    width: 100%;
    background-color: master.$theme-color;
    padding: 30px 60px;

    p {
      color: #fff;
    }

    @include master.xs {
      padding: 20px;
    }
  }
}

.ant-drawer {
  .ant-drawer-close {
    color: #000;
  }
  .ant-drawer-title {
    // text-align: end;
  }
  .sidebar-section {
    justify-content: space-between;
    align-items: center;
    .header-content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @include master.lg {
        p {
          font-size: 16px !important;
        }
      }
    }
    .buttton-sec {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
