@use "../../../../master.scss";

.profile-detail {
  .profile-heading {
    @include master.flex-between;
  }
  .delete-icon {
    background-color: #f43c3c1a;
    border-color: #f43c3c;
  }
}
