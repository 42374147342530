@use "../../../master.scss";

.add-estimate-parent {
  display: flex;
  gap: 15px;

  .customer-section {
    width: 25%;
  }
  .right-section {
    width: 75%;
    position: relative;

    .recent-activity-card {
      border-top: 1px solid master.$border-color;
      padding-top: 15px;
      margin-top: 15px;
    }
  }

  @include master.lg {
    flex-direction: column;
    .customer-section {
      width: 100%;
    }
    .right-section {
      width: 100%;
    }
  }
}
