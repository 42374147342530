@use "../../master.scss";

.drawer-component {
    .drawer-title {
        font-size: 17px;
        padding-bottom: 15px;
        margin-bottom: 30px;
        @include master.text-wrap();
    }

    .bottom-box {
        button {
            margin: 10px 0;
            width: 100%;
            font-size: 16px;
            padding: 10px;

            &.reset-btn {
                padding: 12px;

                @include master.sm {
                    padding: 10px;
                }
            }

            @include master.sm {
                font-size: 14px;
                padding: 8px;
            }
        }
    }
}
