.Common-radio {
  .ant-radio-wrapper {
    .ant-radio {
      .ant-radio-inner {
        border-color: #000;
        background-color: #fff;
        &::after {
          background-color: #000;
          width: 20px;
          height: 20px;
          margin-block-start: -10px;
          margin-inline-start: -10px;
        }
      }
    }
  }
}
