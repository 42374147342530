@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lato:wght@200;300;400;500;600;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@200;300;400;500;600;700&display=swap");
//  ================== VARIABLES ==================

// Font Faces
// @font-face {
//   font-family: seventyTwoLight;
//   src: url(./assets/fonts/72-Light.ttf);
// }
// @font-face {
//   font-family: seventyTwoRegular;
//   src: url(./assets/fonts/72-Regular.ttf);
// }
// @font-face {
//   font-family: seventyTwoSemiBold;
//   src: url(./assets/fonts/72-Semibold.ttf);
// }
// @font-face {
//   font-family: seventyTwoBold;
//   src: url(./assets/fonts/72-Bold.ttf);
// }

// DEFAULT COLORS

// THEME COLORS
$theme-color: #0087a2;
$theme-light-color: #0087a21a;
$border-color: #eff1f2;
$text-color: #18191a;
$text-light-color: #45494c;
$white-color: #ffffff;
$black-color: #000000;

$green-color: #1ebe4b;
$green-light-color: #1ebe4b1a;

$purple-color: #9937e5;
$purple-light-color: #9937e61a;

$blue-color: #0087a2;
$blue-light-color: #0087a21a;

$background-light-color: #f5f6fd;
$text-greenish-color: #3fc8aa;
$white-text: #f6fafd;

// FONTS
$font-poppins: "Poppins", sans-serif;

// RESPONSIVE BREAKPOINTS

$screen-xs: 480px;
// Small devices
$screen-sm: 576px;
// Small tablets (portrait view)
$screen-md: 768px;
// Tablets and small desktops
$screen-lg: 992px;
// Large tablets and desktops
$screen-xl: 1200px;
// Extra large devices
$screen-xxl: 1600px;

//  ================== RESPONSIVE MIXINS ==================

@mixin media-breakpoint-down($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin media-breakpoint-up($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin media-breakdown-up-down($breakpoint-up, $breakpoint-down) {
  @media (min-width: $breakpoint-up) and (max-width: $breakpoint-down) {
    @content;
  }
}

// custom width
@mixin rwd($screen) {
  @media (max-width: #{$screen}px) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$screen-xs}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl}) {
    @content;
  }
}

// responsive text
@mixin text-responsive($size) {
  font-size: $size + px !important;

  @media only screen and (max-width: 767px) {
    font-size: ($size - 1) + px !important;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: $size + px !important;
  }

  @media only screen and (min-width: 992px) {
    font-size: ($size + 1) + px !important;
  }
}

//  ================== STYLING MIXINS ==================

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-start-column {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

@mixin flex-end-column {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin line-wrap($line) {
  -webkit-line-clamp: $line;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

//  ================== GLOBAL CLASESS ==================

// MARGINS

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

// PADDINGS

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

// text

.text-11 {
  @include text-responsive(12);
}

.text-12 {
  @include text-responsive(12);
}

.text-13 {
  @include text-responsive(13);
}

.text-16 {
  @include text-responsive(16);
}

.text-18 {
  @include text-responsive(18);
}

.text-20 {
  @include text-responsive(20);
}
