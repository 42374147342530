@use "../../../master.scss";

.common-modal {
  min-width: 280px !important;
  max-width: 600px !important;
  width: 70% !important;
  padding: 20px 0 20px 0;
  margin: 20px 0;

  .ant-modal-content {
    padding: 0 !important;
    border-radius: 15px;
    // background: master.$grey-color;
    // border: 15px solid master.$light-grey-color;
    // background-color: master.$dark-color;

    .ant-modal-close {
      background: master.$black-color;
      top: 10px;
      right: 10px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      .ant-modal-close-x {
        color: master.$white-color;
        font-size: 12px;
      }
    }

    .ant-modal-header {
      padding: 30px;
      border-radius: 15px;
    }
    .ant-modal-body {
      padding: 0px 30px 20px 30px;
    }
  }

  @include master.sm {
    width: 100% !important;
    padding: 20px 20px 0 20px;
  }
}

.confirmation-modal {
  width: 40% !important;
  min-width: 280px !important;
  .ant-modal-body {
    padding: 20px 30px 20px 30px !important;
    .confirm-content {
      width: 100%;
      margin-top: 20px;
      .button-section {
        display: flex;
        gap: 10px;
        .button-parent {
          width: 100%;
        }
        @include master.sm {
          flex-direction: column;
        }
      }
    }
  }
}
