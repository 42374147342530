@use "../../../../master.scss";

.employee-parent {
  .search-section {
    // display: flex;
    // justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}
