@use "../../../master.scss";

.top-section {
  margin-top: 10px;
  margin-bottom: 25px;
}

.setting-content {
  display: flex;
  gap: 15px;
  .list-section {
    width: 25%;
    @include master.md {
      display: none;
    }
  }

  .list-select {
    display: none;
    .ant-select-selection-item {
      font-weight: 600 !important;
      font-size: 14px;
    }
    @include master.md {
      display: contents;
    }
  }

  .detail-section {
    width: 75%;
    position: relative;
    .edit-icon {
      position: absolute;
      top: -60px;
      right: 0;

      @include master.md {
        top: 9px;
        right: 8px;
      }
    }
    @include master.md {
      width: 100%;
    }
  }
  @include master.md {
    flex-wrap: wrap;
  }
}
