@use "../../../master.scss";

.header-parent {
  .main-header {
    position: fixed;
    top: 0;
    width: calc(100% - 220px);
    padding: 15px 20px 10px 20px;
    border-bottom: 1px solid master.$border-color;
    margin-left: 220px;
    z-index: 2;
    background: #fff;
    @include master.flex-between;
    .right-side-content {
      @include master.flex-center;
      gap: 10px;
      .profile-complete {
        gap: 8px;
        max-width: 250px;
        width: 100%;
        min-width: 100px;
        border: 1px solid master.$theme-color;
        border-radius: 10px;
        background-color: master.$theme-light-color;
        padding: 5px 10px;
        @include master.flex-center;
      }
      .profile-section {
        width: 170px;
        gap: 8px;
        @include master.flex-center;
        .right-arrow {
          width: 10px;
          height: 10px;
        }
      }
      @include master.md {
        display: none;
      }
    }
    @include master.rwd(1000) {
      display: none;
    }
  }

  .mobile-header {
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 15px 20px 10px 20px;
    border-bottom: 1px solid master.$border-color;
    z-index: 2;
    background: #fff;
    display: none;
    height: 88px;

    @include master.rwd(1000) {
      display: flex;
    }
  }
}
