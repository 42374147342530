.spacing {
    margin: 20px 0;
}

.image-upload-profile {
    margin-bottom: 20px;
}

.but-sec {
    display: flex;
    justify-content: end;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    button {
        width: 130px;
    }
}
