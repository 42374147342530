@use "../../../../master.scss";

.company-list-parent {
  border: 1px solid master.$border-color;
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 20px;
  .list-item {
    padding-top: 8px;
    border-top: 1px solid master.$border-color;
    margin-top: 10px;
    p {
      cursor: pointer;
      width: max-content;
    }
  }
  .active {
    p {
      color: master.$theme-color;
      font-weight: 500;
    }
  }
}
