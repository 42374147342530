@use "../../../master.scss";

.ant-select {
  height: 56px;

  .ant-select-selection-overflow-item {
    .ant-select-selection-item {
      display: none;
    }
  }

  .ant-select-selector {
    border-radius: 12px !important;
    font-size: 15px;
    padding: 0 0 0 11px !important;

    .ant-select-selection-item {
      padding-inline-end: 0;
    }
    .ant-select-selection-search {
      margin-inline-start: 0px;
    }
  }
  .ant-select-arrow {
    color: master.$theme-color;
  }

  .ant-select-selection-placeholder {
    color: #96a0ad;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-select {
    height: 38px !important;
    .ant-select-selector {
      font-size: 13px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ant-select {
    height: 40px !important;
    .ant-select-selector {
      font-size: 14px;
    }
  }
}
