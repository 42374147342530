@use "../../../master.scss";

.right {
    flex-direction: row-reverse;
    .right-side {
        margin-right: 50%;
    }
}

.left {
    .right-side {
        margin-left: 50%;
    }
}

.auth-layout-parent {
    display: flex;
    min-height: 600px;

    .left-side {
        background-image: url("../../../assets/auth/authPattern.png");
        min-height: 100vh;
        width: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;

        @include master.md {
            display: none !important;
        }
    }
    .right-side {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 20px;
        position: relative;

        .uplaod-setup-account {
            margin-bottom: 30px;
        }
        .content {
            margin-bottom: 20px;
            max-width: 400px;
                width: 100%;
        }
        .logo-section {
            .logo {
                width: 300px;
                margin-bottom: 20px;
            }
            text-align: center;
            margin-bottom: 40px;
            .headind-parent {
                @include master.flex-center;
                gap: 10px;
            }
        }
        .bottom-text {
            position: absolute;
            bottom: 15px;
            .bottom-link {
                color: master.$theme-color;
                font-weight: 500;
                cursor: pointer;
            }
        }
        @include master.md {
            width: 100%;
            margin: 0;
        }
    }
}
