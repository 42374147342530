@use "../../../master.scss";

.common-password {
  height: 56px;
  border-radius: 12px;
  //   border-color: rgba(133, 140, 148, 0.1);
  // border: none;
  padding: 4px 20px;
  font-size: 16px;

  input {
    font-family: "seventyTwoRegular";

    &::placeholder {
      color: #96a0ad !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .common-password {
    font-size: 13px;
    height: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .common-password {
    font-size: 14px;
    height: 40px;
  }
}
