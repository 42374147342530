@use "../../../master.scss";

.customer-detail-parent {
  .ant-tabs {
    width: 100%;
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        position: relative;
        &::after {
          position: static;
        }
        .ant-tabs-nav-list {
          min-width: 400px;
          width: 100%;
          justify-content: space-between;
          .ant-tabs-tab {
            width: 25%;
            .ant-tabs-tab-btn {
              text-align: center;
              width: 100%;
              p {
                color: master.$text-light-color;
              }
            }
          }
          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              p {
                color: master.$theme-color;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
