@use "../../../master.scss";

.employee-request-parent {
  .search-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    border-bottom: 1px solid master.$border-color;
    padding-bottom: 20px;
  }
}
