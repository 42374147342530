@use "../../../master.scss";

.sidebar-parent {
  background: #f8fafb;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 220px;
  padding: 20px;
  overflow: auto;

  .logo {
    margin-bottom: 40px;
  }

  .sidebar-divider {
    margin: 50px 0 20px 0;
  }

  .sidebar-content {
    .list {
      .list-item {
        cursor: pointer;
        display: flex;
        gap: 15px;
        padding: 20px 0;
        transition: all 0.3s;
      }
      .active {
        svg {
          rect {
            stroke: master.$theme-color;
          }
          path {
            fill: master.$theme-color;
          }
        }

        P {
          color: master.$theme-color !important;
          font-weight: 600;
        }
      }
    }
  }

  .logout-btn {
    margin-top: calc(10vh);
  }

  @include master.rwd(1000) {
    display: none;
  }
}

.ant-drawer {
  .ant-drawer-header {
    padding: 20px 5px;

    .ant-drawer-header-title {
      flex-direction: row-reverse;
      .ant-drawer-close {
        svg {
          fill: #000;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
  }
  .sidebar-parent {
    width: 100%;
    position: static !important;
    display: block !important;
  }
}
