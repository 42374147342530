@use "../../../master.scss";

.waiting-parent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 10px;
    .wait-img {
        margin-top: 60px;

        max-width: 500px;
        min-width: 100px;
        width: 100%;
    }

    .logo-section {
        position: relative;
        width: 100%;
        text-align: center;
        .back-wait {
            position: absolute;
            left: 0;
        }
        .logo-img {
            text-align: center;
            max-width: 300px;
            min-width: 100px;
            width: 100%;
        }

        @include master.xs {
            .back-wait {
                position: static;
                margin-bottom: 10px;
            }
        }
    }

    .content {
        max-width: 500px;
        min-width: 100px;
        width: 100%;
    }
}
