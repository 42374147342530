@use "../../master.scss";

.private-wrapper {
  .private-content {
    padding: 20px 25px;
    margin-top: 88px;
    margin-left: 220px;
    @include master.rwd(1000) {
      margin-left: 0;
    }
  }
}
