@use "../../../master.scss";

.service-detail-parent {
  .img-section {
    margin-bottom: 20px;
    margin-top: 40px;
    img {
      border-radius: 12px;
      width: 199px;
      height: 118px;
    }
  }
}
