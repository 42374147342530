@use "../../../master.scss";

.ant-table-wrapper {
  .ant-table {
    // border: 1px solid master.$border-color;
    .ant-table-thead > tr {
      th {
        background: #fff;
        border-color: master.$border-color;
        color: #243d4c;
        font-weight: 500;
        padding: 16px 10px 16px 0;

        &::before {
          display: none;
        }
      }
      td {
        background: #fff;
      }
    }

    .ant-table-tbody {
      border-color: master.$border-color;

      .ant-table-row {
        td {
          padding: 10px 10px 10px 0 !important;
        }
      }
      tr:hover > td {
        background: none;
      }
    }
  }
  .ant-table-column-sorters {
    justify-content: flex-start;
    gap: 5px;
    .ant-table-column-title {
      flex: unset;
    }
  }

  .ant-table-pagination.ant-pagination {
    margin-top: 40px;
    .anticon {
      font-size: 20px;
      color: master.$theme-color;
    }

    .ant-pagination-item {
      border-radius: 50%;
    }
    .ant-pagination-item-active {
      background-color: master.$theme-color;
      border-radius: 50%;
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
}
